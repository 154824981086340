@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: iconFont;
  src: url('../public/assets/fonts/Nautilus.otf');
}

body {
  margin: 0;
  font-family: "Source Sans 3";
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Ubuntu', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.color_changed{
  background-color: white !important;
  color: black;
  top: 0;
}
#navbar{
  transition: all ease-in 500ms;
}

@keyframes slideInUp {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideInDown {
  0% {
    transform: translateY(-30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-slideInUp {
  animation: slideInUp 1s ease-out forwards;
  animation-delay: 0.2s; 
}

.animate-slideInDown {
  animation: slideInDown 1s ease-out forwards;
  animation-delay: 0.2s; 
}

.icon-font{
  font-family: iconFont;
  font-weight: 300;
}

.remove{
  display: none;
}